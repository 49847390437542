import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Icon,
  typographyVariants,
} from '@photosi/web-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserBillingData, useOrderInfo } from '../../hooks';
import { InvoiceForm } from '../InvoiceForm';
import { Loader } from '../Loader';
import { useGetMutationState } from '../../hooks';
import ErrorModalContent from './ErrorModalContent';
import SuccessModalContent from './SuccessModalContent';
import { Amplitude } from '../../utils/amplitude';
import { useParams } from 'react-router-dom';

interface InvoiceModalProps {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({ isOpen = false, setIsOpen }) => {
  const { t } = useTranslation('payment');

  const {
    data: billingData,
    isLoading: isBillingDataLoading,
    isSuccess: isBillingDataSuccess,
    isError: isBillingDataError,
    is404Error,
  } = useGetUserBillingData({
    enabled: isOpen,
  });

  const createUserBillingData = useGetMutationState('createBillingData');
  const updateUserBillingData = useGetMutationState('updateBillingData');

  const { orderId } = useParams() as {
    orderId: string;
  };
  const orderResponse = useOrderInfo(orderId);

  const billingDataMutationState = {
    isLoading: createUserBillingData === 'loading' || updateUserBillingData === 'loading',
    isError: createUserBillingData === 'error' || updateUserBillingData === 'error',
    isSuccess: createUserBillingData === 'success' || updateUserBillingData === 'success',
  };
  const isNot404Error = isBillingDataError && !is404Error;

  const showInvoiceForm = (isBillingDataSuccess || is404Error) && !billingDataMutationState.isSuccess;
  const onTriggerClick = () => {
    setIsOpen(true);
    Amplitude.invoiceModalOpen();
  };

  const handleOnClose = () => {
    setIsOpen(false);
    if (billingDataMutationState.isSuccess) {
      orderResponse.refetch();
    }
  };

  return (
    <Dialog open={isOpen} modal onOpenChange={setIsOpen}>
      <DialogTrigger asChild data-testid="modal-trigger">
        <Button variant="link" onClick={onTriggerClick} leftElement={<Icon name="file" />}>
          {t('requestInvoice')}
        </Button>
      </DialogTrigger>
      <DialogContent
        className="pds-max-w-[600px] pds-max-h-[630px] pds-h-[630px] pds-justify-start pds-py-8 pds-px-3"
        showCloseIcon={false}
        data-testid="invoice-modal"
      >
        <DialogHeader className="pds-flex pds-flex-row  pds-items-center pds-px-3">
          <DialogTitle
            className={typographyVariants({ variant: 'headingL', className: !showInvoiceForm ? 'pds-hidden' : '' })}
          >
            {t('invoiceData')}
          </DialogTitle>
          <Button variant="link" onClick={handleOnClose} className="pds-p-0 pds-h-fit pds-ml-auto">
            <Icon name="x" />
          </Button>
        </DialogHeader>
        {isBillingDataLoading && (
          <div className="pds-h-full pds-flex pds-items-center pds-justify-center">
            <Loader />
          </div>
        )}
        {isNot404Error && <ErrorModalContent />}
        {billingDataMutationState.isSuccess && <SuccessModalContent closeModal={handleOnClose} />}
        {showInvoiceForm && <InvoiceForm data={billingData} isUserFirstAccess={is404Error} />}
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceModal;
