import { Button, buttonVariants, mergeClasses, Typography } from '@photosi/web-design-system';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useOrderInfo } from '../../hooks/useOrderInfo';
import { useUser } from '../../contexts/UserContext';
import { getCancelOrderCounter, isAppOrder } from '../../utils';
import { useEffect, useState } from 'react';
import { Amplitude } from '../../utils/amplitude';
import { getFeatureFlagEnabled } from '../../utils/getFeatureFlag';
import OrderStateEnum from '../../types/OrderState';
import { CancelOrderErrorCodes, CancelOrderErrorDialog } from '../../components/CancelOrderErrorDialog';
import { useDeleteHeader } from '../../hooks/useDeleteHeader';
import { CancelOrderForbiddenError } from '../../components/CancelOrderForbiddenError';
import { Error } from '../../components/Error';
import { Timer } from '../../components/Timer';
import { CancelOrderReasons } from '../../components/CancelOrderReasons';
import { CancelOrderDisabledDialog } from '../../components/CancelOrderDisabledDialog';
import { CancelOrderSuccessDialog } from '../../components/CancelOrderSuccessDialog';
import OrderStatusBanner from '../../components/OrderStatusBanner/OrderStatusBanner';
import { Loader } from '../../components/Loader';

const CancelOrder: React.FC = () => {
  const { orderId } = useParams() as {
    orderId: string;
  };
  const [errorDialogCode, setErrorDialogCode] = useState<CancelOrderErrorCodes>('GENERIC_ERROR');
  const [isErrorDialogOpened, setIsErrorDialogOpened] = useState(false);
  const [isSuccessDialogOpened, setIsSuccessDialogOpened] = useState(false);
  const [cancelReason, setCancelReason] = useState('placeholder');
  const [isSelectOpened, setIsSelectOpened] = useState(false);
  const { data, isLoading, isError } = useOrderInfo(orderId);
  const { mutate, isLoading: isOrderCancelling } = useDeleteHeader();
  const { button } = buttonVariants();
  const { state: locationState } = useLocation();
  const isCancelOrderFlagEnabled = getFeatureFlagEnabled('enableOrderCancellation');

  useEffect(() => {
    async function triggerPageViewEvents() {
      if (locationState === 'my_order') {
        await Amplitude.orderDeleteView('my_order');
      } else {
        await Amplitude.orderDeleteView('thankyoupage');
      }
    }
    triggerPageViewEvents();
  }, [locationState]);

  const { t } = useTranslation('cancelOrder');
  const { userId } = useUser();

  const correctUser = `${data?.userId}` === userId;

  const onDeleteOrder = () => {
    Amplitude.orderDeleteConfirmClick(cancelReason);
    mutate(
      { reason: cancelReason, orderId },
      {
        onSuccess: () => {
          setIsSuccessDialogOpened(true);
          Amplitude.orderDeleteSuccess();
        },
        onError: (error: any) => {
          setErrorDialogCode(error.type as CancelOrderErrorCodes);
          setIsErrorDialogOpened(true);
          Amplitude.orderDeleteError(error.type);
        },
      },
    );
  };

  if (isLoading) return <Loader data-testid="loader" />;
  if (!correctUser) return <CancelOrderForbiddenError />;
  if (isError || !data) return <Error data-testid="error" />;

  return (
    <div className="pds-flex pds-flex-col pds-items-center pds-w-full" data-testid="page-content">
      <Typography variant="heading3XL" className="pds-font-medium pds-mb-4 pds-text-center">
        {t('title')}
      </Typography>
      <div className="pds-space-y-4 pds-flex-col pds-flex pds-max-w-3xl" data-testid="page-container">
        <OrderStatusBanner
          leftElement={
            <Timer
              data-testid="timer"
              startTime={getCancelOrderCounter({
                confirmedAt: data.creationDate,
                cancellableUntil: data.maxCancellationDate ?? undefined,
              })}
              onTimerEnd={() => {
                setErrorDialogCode('TIMER_EXPIRED');
                setIsErrorDialogOpened(true);
              }}
            />
          }
          className="md:pds-items-center pds-gap-4 pds-rounded-2xl md:pds-gap-2 pds-flex-row"
        >
          <Typography>{t('canCancelOrder')}</Typography>
        </OrderStatusBanner>
        <Trans
          t={t}
          i18nKey="cancelOrderConditions"
          components={{
            t1: <Typography className="!pds-mt-8" />,
            t2: <Typography />,
            u: <ul className="pds-list-disc pds-pl-6" />,
            l: <li />,
          }}
        />
        <Typography className="pds-font-bold">{t('orderChanges')}</Typography>
        {isAppOrder(data.softwareCode) ? (
          <Typography data-testid="app-description">{t('orderChangesDescriptionApp')}</Typography>
        ) : (
          <Typography data-testid="web-description">{t('orderChangesDescriptionWeb')}</Typography>
        )}
      </div>
      <div className="!pds-mt-8 pds-flex pds-flex-col pds-gap-4 pds-w-full pds-max-w-3xl">
        <CancelOrderReasons onSelectReason={setCancelReason} onTriggerSelect={setIsSelectOpened} />
        <div className="pds-flex pds-flex-col md:pds-flex-row pds-gap-4 pds-mt-8">
          <Link
            to={{ pathname: '/orders', search: `page=1` }}
            className={mergeClasses(
              button({ size: 'md', className: 'pds-text-center pds-w-full', variant: 'outline' }),
              isSelectOpened ? 'pds-pointer-events-none' : 'pds-pointer-events-auto',
            )}
          >
            <span className="pds-text-lg pds-pl-2 pds-align-top pds-text-water-500">
              {t('goBack', { ns: 'orderDetail' })}
            </span>
          </Link>
          <Button
            size="md"
            className="pds-w-full"
            disabled={!cancelReason || cancelReason === 'placeholder' || isSelectOpened}
            isLoading={isOrderCancelling}
            onClick={onDeleteOrder}
          >
            {t('cancelOrderButton')}
          </Button>
        </div>
      </div>
      <CancelOrderDisabledDialog isOpened={!isCancelOrderFlagEnabled && data.orderState !== OrderStateEnum.Cancelled} />
      <CancelOrderSuccessDialog isOpened={isSuccessDialogOpened || data.orderState === OrderStateEnum.Cancelled} />
      <CancelOrderErrorDialog
        isOpened={
          isErrorDialogOpened && data.orderState !== OrderStateEnum.Cancelled && (isCancelOrderFlagEnabled ?? false)
        }
        code={errorDialogCode}
        onRetry={() => {
          setIsErrorDialogOpened(false);
          setErrorDialogCode('GENERIC_ERROR');
        }}
      />
    </div>
  );
};

export default CancelOrder;
