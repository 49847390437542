import { formatInvoiceFields } from '../components/InvoiceForm/utils/formatInvoiceFields';
import { API_ACCOUNTS } from '../settings';
import { BillingData } from '../types/BillingData';
import createInvoiceOrder from './createInvoiceOrder';

interface UpdatedData extends Partial<BillingData> {
  shopCode: string;
  paymentType: string;
  email: string;
}

const createUserBillingData = async (id: number, apiOptions: RequestInit, data: UpdatedData, orderNumber: string) => {
  const nextData = formatInvoiceFields({ ...data });
  const billingDataPromise = fetch(`${API_ACCOUNTS}/${id}/billing-data`, {
    ...apiOptions,
    body: JSON.stringify(nextData),
  });

  const fetchResponse = await billingDataPromise;
  if (!fetchResponse.ok) {
    throw new Error(await fetchResponse.json());
  }

  const createInvoiceOrderPromise = createInvoiceOrder(apiOptions, orderNumber);
  const createInvoiceOrderResponse = await createInvoiceOrderPromise;
  if (!createInvoiceOrderResponse.ok) {
    throw new Error(await createInvoiceOrderResponse.json());
  }
};

export default createUserBillingData;
