import { Button, Icon, Typography } from '@photosi/web-design-system';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGetInvoiceUrl } from '../../../../hooks';
import { downloadDocument } from '../../utils/downloadDocument';
import { Amplitude } from '../../../../utils/amplitude';

type InvoiceButtonProps = {
  isInProgress: boolean;
  orderNumber?: number;
};

const InvoiceButton: React.FC<InvoiceButtonProps> = ({ isInProgress, orderNumber }) => {
  const { t } = useTranslation('payment');
  const invoiceUrlResponse = useGetInvoiceUrl();

  const invoiceDisabled = invoiceUrlResponse.isLoading || invoiceUrlResponse.isError || isInProgress;
  const downloadInvoice = () => {
    downloadDocument(invoiceUrlResponse.data?.url as string, `${t('invoiceForOrderNumber')} ${orderNumber}`);
    Amplitude.downloadInvoice();
  };
  const isError = invoiceUrlResponse.isError && !invoiceUrlResponse.is404Error;
  const isInvoiceInProgress = isInProgress || invoiceUrlResponse.isLoading;
  const errorIcon = isError ? 'alert' : 'inProgress';

  return (
    <Button
      variant="link"
      leftElement={<Icon name={invoiceUrlResponse.isSuccess ? 'download' : errorIcon} className="pds-min-w-6" />}
      data-testid="invoice-download-button"
      onClick={downloadInvoice}
      disabled={invoiceDisabled}
      className="pds-text-left"
    >
      {isInvoiceInProgress ? (
        <Trans i18nKey="invoiceInProgress" t={t} />
      ) : (
        <Typography className={isError ? 'pds-text-red-400' : 'pds-text-water-500'}>
          {isError ? t('documentError') : t('downloadInvoice')}
        </Typography>
      )}
    </Button>
  );
};

export default InvoiceButton;
