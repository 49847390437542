import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@photosi/web-design-system';
import { useOrderInfo } from '../../hooks';
import { useParams } from 'react-router-dom';
import { checkAddresses } from './utils/checkAddresses';
import { z } from 'zod';
import { formSchema } from './utils/formSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { BillingData } from '../../types/BillingData';
import useUpdateUserBillingData from '../../hooks/useUpdateUserBillingData/useUpdateUserBillingData';
import { CLIENT_TYPE } from '../../utils/consts/clientType';
import IOrder from '../../types/Order';
import useCreateUserBillingData from '../../hooks/useCreateUserBillingData/useCreateUserBillingData';
import { CompanyForm, ConsumerForm, UserRadioGroup, AddressRadioGroup } from './components';
import AddressForm from './components/AddressForm';
import { Amplitude } from '../../utils/amplitude';

export type InvoiceFormSchema = z.infer<typeof formSchema>;

interface InvoiceFormProps {
  data: BillingData;

  isUserFirstAccess: boolean;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({ data, isUserFirstAccess }) => {
  const { t } = useTranslation('payment');

  const { orderId } = useParams() as {
    orderId: string;
  };
  const { data: orderInfo } = useOrderInfo(orderId);

  const isInvoiceAddressMatchingShippingAddress = checkAddresses(orderInfo as IOrder, data);
  const methods = useForm<InvoiceFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: data?.name || '',
      surname: data?.surname || '',
      fiscalCode: data?.fiscalCode || '',
      company: data?.company || '',
      vatCode: data?.vatCode || '',
      sdiCode: data?.sdiCode || '',
      pec: data?.pec || '',
      sameBillingShippingAddress: isInvoiceAddressMatchingShippingAddress,
      sdiCodeCheck: data?.pec && data.pec.length > 0 ? true : false,
      street: data?.street || `${orderInfo?.shippingAddressStreet}, ${orderInfo?.shippingAddressNumber}`,
      city: data?.city || (orderInfo?.shippingAddressCity as string),
      zipCode: data?.zipCode || (orderInfo?.shippingAddressZipCode as string),
      country: data?.country || (orderInfo?.shippingAddressCountry as string),
      area: data?.area || (orderInfo?.shippingAddressArea as string),
      clientType: data?.clientType || CLIENT_TYPE.COMPANY,
    },
    mode: 'onSubmit',
  });
  const { handleSubmit, watch, getValues } = methods;
  const createUserBillingData = useCreateUserBillingData();
  const updateUserBillingData = useUpdateUserBillingData();

  const onSubmit = (formData: InvoiceFormSchema) => {
    if (isUserFirstAccess) {
      // nextData is taking even the empty values
      const nextData = { ...getValues(), ...formData };

      createUserBillingData.mutate(nextData);
    } else {
      const nextData = { ...getValues() };
      const notAllowedKeys = ['clientType', 'fiscalCode', 'company', 'vatCode', 'name', 'surname'];
      const dataToSubmit = Object.fromEntries(
        Object.entries(nextData).filter(([key]) => !notAllowedKeys.includes(key)),
      );

      //TODO: temporary fix. Shopcode should be retrieve on the BE side
      const dataWithShopCode = { ...dataToSubmit, shopCode: data?.shopCode };

      updateUserBillingData.mutate(dataWithShopCode as Partial<InvoiceFormSchema>);
    }
    Amplitude.requestInvoice({
      client_type: data.clientType ? 'company' : 'private',
      invoice_address_same_as_shipping: formData.sameBillingShippingAddress ? 'yes' : 'no',
      no_addressee_code: 'sdiCodeCheck' in formData ? formData.sdiCodeCheck : false,
    });
  };

  const sameAddressesWatcher = watch('sameBillingShippingAddress');
  const isCompanyWatcher = watch('clientType') === CLIENT_TYPE.COMPANY;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pds-mt-6 pds-grow pds-flex pds-flex-col pds-overflow-hidden"
        noValidate
      >
        {isUserFirstAccess && <UserRadioGroup isCompanyWatcher={isCompanyWatcher} clientType={data?.clientType} />}
        {(createUserBillingData.isError || updateUserBillingData.isError) && (
          <div className="pds-bg-red-400 pds-bg-opacity-25 pds-text-left pds-px-4 pds-py-3 pds-rounded-xl pds-mb-6 pds-mx-3">
            <Typography variant="bodyXS" className="pds-text-red-400 ">
              {t('checkRequiredFields')}
            </Typography>
          </div>
        )}
        <div
          className="pds-overflow-auto pds-box-content pds-px-3 pds-mb-3 pds-grow pds-scrollbar"
          style={{ maskImage: 'linear-gradient(to bottom, black 95%, transparent)' }}
        >
          {isCompanyWatcher ? (
            <CompanyForm data={data} isUserFirstAccess={isUserFirstAccess} />
          ) : (
            <ConsumerForm isUserFirstAccess={isUserFirstAccess} />
          )}

          <div className="pds-flex pds-flex-col pds-grow pds-border-t">
            <AddressRadioGroup
              defaultValue={isInvoiceAddressMatchingShippingAddress ? 'yes' : 'no'}
              sameAddressesWatcher={sameAddressesWatcher}
              isUserFirstAccess={isUserFirstAccess}
            />

            <div className="pds-mb-6">
              {!sameAddressesWatcher || !isUserFirstAccess ? (
                <AddressForm />
              ) : (
                <div className="pds-flex pds-flex-col pds-items-start" data-testid="shipping-address">
                  <Typography variant="bodySpacedS" className="pds-text-gray-400">
                    {orderInfo?.shippingAddressStreet},&nbsp;{orderInfo?.shippingAddressNumber}
                  </Typography>
                  <Typography variant="bodySpacedS" className="pds-text-gray-400">
                    {orderInfo?.shippingAddressZipCode}&nbsp;{orderInfo?.shippingAddressCity}&nbsp;
                    {orderInfo?.shippingAddressArea}, {orderInfo?.shippingAddressCountry}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button
          className="pds-justify-self-end pds-min-h-12 pds-mx-3"
          size="md"
          type="submit"
          isLoading={createUserBillingData.isLoading || updateUserBillingData.isLoading}
        >
          {t('requestInvoice')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default InvoiceForm;
