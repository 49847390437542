import { API_ORDER_INVOICE } from '../settings';

const createInvoiceOrder = async (apiOptions: RequestInit, orderNumber: string) => {
  const options = { ...apiOptions, method: 'POST' };
  const response = await fetch(`${API_ORDER_INVOICE}`, {
    ...options,
    body: JSON.stringify({ orderGuid: orderNumber }),
  });

  if (!response.ok) {
    throw new Error(await response.json());
  }
  return await response;
};

export default createInvoiceOrder;
